import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import axios from "axios";
import { TypedUser } from "../../models/index.model";

interface InitialState {
  account?: TypedUser,
  loggedIn: boolean,
  share_chat: boolean,
  loading: boolean,
  error: string
}

export const initialState: InitialState = {
  account: undefined,
  loggedIn: localStorage.getItem("access_token") ? true : false,
  share_chat: true,
  loading: false,
  error: ""
};

interface BodyLoginPassword {
  user_email: string
  password: string
}

interface BodyRegisterPassword {
  user_name: string
  user_email: string
  password: string
}

export const loginWithPassword = createAsyncThunk("user/login-password", async (data: BodyLoginPassword) => {
  const res = await axios.post<{ access_token: string, refresh_token: string }>(`auth/login`, data);
  if (res.data) {
    localStorage.setItem("access_token", res.data.access_token)
    localStorage.setItem("refresh_token", res.data.refresh_token)
  }
  return res;
});

export const register = createAsyncThunk("user/register", async (data: BodyRegisterPassword): Promise<TypedUser> => {
  const res =  await axios.post<TypedUser>(`auth/register`, data);
  return res.data
});

export const logout = createAsyncThunk("logout", async ({ callApi = true }: { callApi: boolean }) => {
  localStorage.removeItem("access_token")
  localStorage.removeItem("refresh_token")
  if (callApi) {
    axios.post(`auth/logout`);
  }
  return Promise.resolve()
});

export const getUserInfo = createAsyncThunk("user/me", async () => {
  const res = await axios.get<TypedUser>(`user/me`);
  return res
});

export const USER = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    // setShareChat: (state, action) => {
    //   state.share_chat = action.payload
    // },
  },

  extraReducers(builder) {
    builder
      .addMatcher(isPending(loginWithPassword), (state, action) => {
        state.loading = true
        state.error = ""
      })
      .addMatcher(isFulfilled(loginWithPassword), (state, action) => {
        state.loggedIn = true
        state.error = ""
        state.loading = false
      })
      .addMatcher(isRejected(loginWithPassword), (state, action) => {
        state.error = action.error.message || ""
        state.loading = false
      })
      .addMatcher(isFulfilled(getUserInfo), (state, action) => {
        state.account = action.payload.data
      })
      .addMatcher(isFulfilled(logout), (state, action) => {
        state.account = undefined
        state.loggedIn = false
      })
      .addMatcher(isRejected(logout), (state, action) => {
        state.account = undefined
        state.loggedIn = false
      })
  },
});
// Reducer
export const {
  // setShareChat
} = USER.actions;
export default USER.reducer;
