import { createSlice } from "@reduxjs/toolkit";
import { SideBarTypes } from "../../constants";

interface InitialState {
  sidebarType: SideBarTypes,
}

export const initialState: InitialState = {
  sidebarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT
};

export const SYSTEM = createSlice({
  name: "system",
  initialState: initialState,
  reducers: {
    changeSidebarType: (state, action) => {
      state.sidebarType = action.payload;
    },
  }
});
// Reducer
export const {
  changeSidebarType
} = SYSTEM.actions;
export default SYSTEM.reducer;
