import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { LayoutTypes, LayoutWidth, SideBarTheme, SideBarTypes } from "../constants";
import DefaultLayout from "../layouts/default.layout";
import VerticalLayout from "../layouts/vertical.layout";
import { useAppDispatch, useAppSelector } from "../store";
import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";
import { getUserInfo } from "../reducers/user/user.reducer";
import { getListTask } from "../reducers/home/home.reducer";
import ReactPixel from 'react-facebook-pixel';

interface IRoutesProps { }

const AllRoutes = (props: IRoutesProps) => {
  const loggedIn = useAppSelector(state => state.user.loggedIn)
  const dispatch = useAppDispatch()

  const advancedMatching:any = { em: 'hocong.bkq@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('1060455178756667', advancedMatching, options);

ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
  useEffect(() => {
    if (loggedIn) {
      dispatch(getListTask())
      dispatch(getUserInfo())
    }
  }, [loggedIn])

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={{
                  layoutType: LayoutTypes.LAYOUT_VERTICAL,
                  layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
                  leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
                  leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
                  showRightSidebar: false
                }}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                !loggedIn ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      // hash:route.path,
                      search: "next=" + route.path,
                    }}
                  />
                ) : (
                  <VerticalLayout {...props}>{route.element}</VerticalLayout>
                )
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
