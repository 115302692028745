import { createAsyncThunk, createSlice, isFulfilled } from "@reduxjs/toolkit";
import axios from "axios";
import { TypedTask } from "../../models/index.model";

interface InitialState {
  tasks: TypedTask[]
  total_task: number
}

export const initialState: InitialState = {
  tasks: [],
  total_task: 0
};

export const getListTask = createAsyncThunk("home/task", async () => {
  return await axios.get<TypedTask[]>(`task`);
});

export const createTask = createAsyncThunk("home/create-task", async (data: any) => {
  return await axios.post<TypedTask>(`task`, data);
});

export const updateTask = createAsyncThunk("home/update-task", async (data: Partial<TypedTask>) => {
  return await axios.patch<TypedTask>(`task/${data._id}`, data);
});

export const HOME = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    // setShareChat: (state, action) => {
    //   state.share_chat = action.payload
    // },
  },

  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getListTask), (state, action) => {
        state.tasks = action.payload.data
        state.total_task = action.payload.headers?.['x-total'] || 0
      })
      .addMatcher(isFulfilled(createTask), (state, action) => {
        state.tasks = [...state.tasks, action.payload.data]
        state.total_task = state.total_task + 1
      })
      .addMatcher(isFulfilled(updateTask), (state, action) => {
        state.tasks = state.tasks.map((task) => task._id === action.payload.data._id ? action.payload.data : task)
      })
  },
});
// Reducer
export const {
  // setShareChat
} = HOME.actions;
export default HOME.reducer;
