import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { bindActionCreators } from 'redux';
import { logout } from '../reducers/user/user.reducer';
import getStore from '../store';

const TIMEOUT = Number(process.env.REACT_APP_API_REQUEST_TIMEOUT) || 10000
axios.defaults.timeout = TIMEOUT
axios.defaults.baseURL = process.env.REACT_APP_AJAX_URL
axios.defaults.headers['X-Authorization'] = localStorage.getItem('session')

const store = getStore()
const actions = bindActionCreators({ logout }, store.dispatch);

let isRefreshing = false;
let failedQueue: { resolve: (value: any) => void, reject: any }[] = [];

const processQueue = (error: null, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export default function setupAxiosInterceptors() {
  const onRequestSuccess = (config: InternalAxiosRequestConfig) => { // Specify return type
    config.headers = new axios.AxiosHeaders({
      ...config.headers, // Merge existing headers
      'X-Authorization': localStorage.getItem('access_token') || ''
    })
    return config
  }

  const onResponseSuccess = (response: AxiosResponse) => {
    return response
  }
  const onResponseError = (error: any) => {
    const originalRequest = error.config;

    const access_token = localStorage.getItem("access_token")
    const refresh_token = localStorage.getItem("refresh_token")

    if (originalRequest.url.includes("auth/logout") && !access_token) {
      return
    }
    /***************
     * 401: User not (correctly) authenticated, the resource/page require authentication
     * 403: User's role or permissions does not allow to access requested resource, for instance user is not an administrator and requested page is for administrators.
     */

    if (originalRequest.url.includes("auth/refresh-token") && error.response?.status === 401) {
      console.log("Refresh token failed");
      actions.logout({ callApi: false })
      return
    }

    if (Boolean(access_token) && Boolean(refresh_token) && error.response?.status === 401) {
      console.log("----------------------------------------------------");
      console.log("Refresh token now", isRefreshing);

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['X-Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.post('/auth/refresh-token', { refresh_token })
          console.log("res", res.data);

          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          axios.defaults.headers.common['X-Authorization'] = 'Bearer ' + res.data.access_token;
          originalRequest.headers['X-Authorization'] = 'Bearer ' + res.data.access_token;
          processQueue(null, res.data.access_token);
          resolve(axios(originalRequest));
        } catch (error) {
          failedQueue = []
          actions.logout({ callApi: false })
          reject(error);
        } finally {
          isRefreshing = false;
        }
      });
    }

    return Promise.reject(error?.response?.data)
  }

  const onRequestError = (err: any) => {
    console.log('===>request Error: ', err)
    return Promise.reject(err)
  }

  axios.interceptors.request.use(onRequestSuccess, onRequestError)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}
