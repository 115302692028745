import { combineReducers } from "redux";

import user from "./user/user.reducer";
import home from "./home/home.reducer";
import system from "./system/system.reducer";

const rootReducer = combineReducers({
  user,
  home,
  system
});

export default rootReducer;

export type RootReducer = ReturnType<typeof rootReducer>