import React, { PropsWithChildren, Suspense, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

// constants
// import { LayoutTypes, SideBarTypes } from '../constants';

// utils
import { SideBarTypes } from "../constants";
import { useViewport } from "../hooks/useViewPort";
import { changeSidebarType } from "../reducers/system/system.reducer";
import { useAppDispatch, useAppSelector } from "../store";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import("./topbar"));
const LeftSidebar = React.lazy(() => import("./left.sidebar"));

const loading = () => <div className=""></div>;

const VerticalLayout = ({ children }: PropsWithChildren) => {
  const { width } = useViewport();
  const dispatch = useAppDispatch();
  const sidebarType = useAppSelector((state) => state.system.sidebarType);

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  /*
  layout defaults
  */

  const changeHTMLAttribute = (attribute: string, value: string): void => {
    if (document.body)
      document.getElementsByTagName("html")[0].setAttribute(attribute, value);
  };

  useEffect(() => {
    changeHTMLAttribute("data-sidenav-size", sidebarType);
  }, [sidebarType]);

  useEffect(() => {
    if (width < 1140) {
      dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_FULL));
      // document.getElementsByTagName('html')[0].classList.add('sidebar-enable')
    } else if (width >= 1140) {
      dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT));
      document
        .getElementsByTagName("html")[0]
        .classList.remove("sidebar-enable");
    }
  }, [width]);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened((prevState) => !prevState);

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove("sidebar-enable");
      } else {
        document.body.classList.add("sidebar-enable");
      }
    }
  };

  const isCondensed: boolean = sidebarType === SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;

  return (
    <>
      <div id="wrapper">
        <Suspense fallback={loading()}>
          <LeftSidebar isCondensed={isCondensed} hideLogo={false} />
        </Suspense>

        <div className="content-page">
          <Suspense fallback={loading()}>
            <Topbar openLeftMenuCallBack={openMenu} />
          </Suspense>

          <div className="content">
            <Container fluid>
              <Suspense fallback={loading()}>{children}</Suspense>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default VerticalLayout;
