import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";
import { useAppSelector } from "../store";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const loggedIn = useAppSelector(state => state.user.loggedIn)
  const account = useAppSelector(state => state.user.account)

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (!loggedIn) {
          // not logged in so redirect to login page with the return url
          return (
            <Navigate
              // state={from: props['path']}
              to={{
                pathname: "/auth/login",
                // state: { from: props['path'] },
              }}
            />
          );
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(account?.user_role) !== -1) {
          // role not authorised so redirect to login page
          return <Navigate to={{ pathname: "/" }} />;
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
